import { IPhoto } from "./types";

import placeholder from "./images/placeholder-photo.jpeg";

import warbler1 from "./images/warbler1.png";
import warbler2 from "./images/warbler2.png";
import warbler3 from "./images/warbler3.png";
import warbler4 from "./images/warbler4.png";
import warbler5 from "./images/warbler5.png";

import jobly1 from "./images/jobly1.png";
import jobly2 from "./images/jobly2.png";
import jobly3 from "./images/jobly3.png";
import jobly4 from "./images/jobly4.png";
import jobly5 from "./images/jobly5.png";

import sharebnb1 from "./images/sharebnb-1.png";
import sharebnb2 from "./images/sharebnb-2.png";
import sharebnb3 from "./images/sharebnb-3.png";
import sharebnb4 from "./images/sharebnb-4.png";
import sharebnb5 from "./images/sharebnb-5.png";
import sharebnb6 from "./images/sharebnb-6.png";
import sharebnb7 from "./images/sharebnb-7.png";

import fipfitMetrics1 from "./images/fipfit-metrics-1.png";
import fipfitMetrics2 from "./images/fipfit-metrics-2.png";
import fipfitMetrics3 from "./images/fipfit-metrics-3.png";
import fipfitMetrics4 from "./images/fipfit-metrics-4.png";
import fipfitMetrics5 from "./images/fipfit-metrics-5.png";

import fipfitCalendar1 from "./images/fipfit-calendar-1.png";
import fipfitCalendar2 from "./images/fipfit-calendar-2.png";
import fipfitCalendar3 from "./images/fipfit-calendar-3.png";
import fipfitCalendar4 from "./images/fipfit-calendar-4.png";
import fipfitCalendar5 from "./images/fipfit-calendar-5.png";


const placeholderPhotos: IPhoto[] = [
  {
    src: placeholder,
    alt: 'placeholder',
  },
  {
    src: placeholder,
    alt: 'placeholder',
  },
]

const warblerPhotos: IPhoto[] = [
  {
    src: warbler1,
    alt: `Warbler homepage`
  },
  {
    src: warbler2,
    alt: `Warbler login page`
  },
  {
    src: warbler3,
    alt: `Warbler tweets page`
  },
  {
    src: warbler4,
    alt: `Warbler tweet details page`
  },
  {
    src: warbler5,
    alt: `Warbler user details page`
  },
]

const joblyPhotos: IPhoto[] = [
  {
    src: jobly1,
    alt: `Jobly homepage`
  },
  {
    src: jobly2,
    alt: `Jobly signup page`
  },
  {
    src: jobly3,
    alt: `Jobly welcome back page`
  },
  {
    src: jobly4,
    alt: `Jobly jobs list page`
  },
  {
    src: jobly5,
    alt: `Jobly companies list page`
  },
]

const sharebnbPhotos: IPhoto[] = [
  {
    src: sharebnb1,
    alt: `Sharebnb homepage`
  },
  {
    src: sharebnb2,
    alt: `Sharebnb login page`
  },
  {
    src: sharebnb3,
    alt: `Sharebnb sign up page`
  },
  {
    src: sharebnb4,
    alt: `Sharebnb welcome back page`
  },
  {
    src: sharebnb5,
    alt: `Sharebnb listings page`
  },
  {
    src: sharebnb6,
    alt: `Sharebnb my listings page`
  },
  {
    src: sharebnb7,
    alt: `Sharebnb post new listing page`
  },
]

const fipfitMetricsPhotos: IPhoto[] = [
  {
    src: fipfitMetrics1,
    alt: `Fipfit Metrics empty form`,
  },
  {
    src: fipfitMetrics2,
    alt: `Fipfit Metrics filled form`,
  },
  {
    src: fipfitMetrics3,
    alt: `Fipfit Metrics metrics graph`,
  },
  {
    src: fipfitMetrics4,
    alt: `Fipfit Metrics backend swagger form`,
  },
  {
    src: fipfitMetrics5,
    alt: `Fipfit Metrics backend swagger response`,
  },
]

const fipfitCalendarPhotos: IPhoto[] = [
  {
    src: fipfitCalendar1,
    alt: `Fipfit calendar mobile view`,
  },
  {
    src: fipfitCalendar2,
    alt: `Fipfit calendar modal overlay view`,
  },
  {
    src: fipfitCalendar3,
    alt: `Fipfit calendar backend swagger form`,
  },
  {
    src: fipfitCalendar4,
    alt: `Fipfit calendar backend swagger response`,
  },
  {
    src: fipfitCalendar5,
    alt: `Fipfit calendar backend swagger response 2`,
  },
]

export {
  placeholderPhotos,
  warblerPhotos,
  joblyPhotos,
  sharebnbPhotos,
  fipfitMetricsPhotos,
  fipfitCalendarPhotos,
};
