import "./ProjectSection.css";
import ProjectCard from "./ProjectCard";

import { fipfitCalendarPhotos, fipfitMetricsPhotos } from '../../photos';

/**
 *
 */

function WorkSampleSection() {
    const PROJECT_OBJECTS = [
        {
            title: `Metrics Feature`,
            blurb: `Feature that queries the logged workouts in the database based on workout name, metric (e.g. "Reps", "Weight", etc), and time period. Calls API backend route and displays the graph over time.`,
            skills: [
            ],
            linkTuples: [
            ],
            photos: fipfitMetricsPhotos
        },
        {
            title: `Calendar Feature`,
            blurb: `Feature that displays calendar and calendar dots on days with workouts. Clicking a day displays the workout summaries as tiles. Clicking the tiles displays a pop-up modal of the full workout details.`,
            skills: [
            ],
            linkTuples: [
            ],
            photos: fipfitCalendarPhotos
        },
    ];

    return (
        <section
            className="projectSection d-flex flex-column align-items-center justify-content-center"
            id="work-samples"
        >
            <h2
                className="font-link projectHeading align-middle"
            >
                Work Samples
            </h2>

            {
                PROJECT_OBJECTS.map(
                    (obj, idx) => {
                        return (
                            <div key={idx} className={'ProjectCardWrapper'}>
                                <ProjectCard
                                    key={idx}
                                    title={obj.title}
                                    blurb={obj.blurb}
                                    linkTuples={obj.linkTuples}
                                    photos={obj.photos}
                                />
                            </div>
                        );
                    }
                )
            }

        </section>
    );
}

export default WorkSampleSection;
